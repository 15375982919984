<template>
  <div>
    <PageHeaderDisplay
      title="Dashboard"
      sub-title="Detailed graphs and tables for your portfolios and assets"
      category="Analytics"
      category-logo="fa-file-chart-column"
      :category-route="{ name: $route.name }"
    />
    <div class="content">
      <div class="bg-lighter rounded-md px-4 py-3 mb-4">
        <div class="d-flex align-items-center">
          <div v-if="options.type === 'asset'" class="col-md-3 mb-3 mb-md-0 d-flex align-items-center flex-grow-1 border-right pr-3 mr-3">
            <div class="form-static-text pr-3">Site</div>
            <FormItem
              id="asset"
              :value="options.selectedAssetId"
              class="mb-0"
              type="select2"
              style="width: 240px"
              :options="sortedAssets.map(a => ({ label: a.siteName, value: a._id }))"
              @input="onSelectAsset"
            />
          </div>
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Date Range</div>
            <FormItem
              id="dateRange"
              v-model="options.dateRange"
              type="dateRangePicker"
              :disabled="loadingAction.getConsumptions"
              class="mb-0"
              @input="onDateRangeUpdate"
            />
          </div>
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Compared To</div>
            <FormItem
              id="graph-compare-period"
              v-model="options.comparePeriod"
              class="mb-0"
              type="select"
              style="width: 300px"
              :options="comparePeriods"
              @input="onComparePeriodUpdate"
            />
          </div>
          <div v-if="$route.path !== '/analytics/costs'" class="d-flex align-items-center">
            <div class="form-static-text pr-3">Source</div>
            <FormItem
              id="source"
              v-model="options.source"
              class="mb-0"
              type="select"
              :options="[
                { label: 'Combined', value: 'combined' },
                { label: 'Invoice', value: 'invoice' },
                { label: 'Readings', value: 'reading' },
                { label: 'Custom Consumption', value: 'custom' },
                { label: 'HH Data', value: 'hh' }
              ]"
              @input="onSourceUpdate"
            />
          </div>
        </div>

        <!-- <div v-else-if="options.type === 'portfolio'" class="col-md-3 mb-3 mb-md-0 d-flex align-items-center border-left">
          <div class="font-w600 mr-3 text-capitalize" style="white-space: pre">{{ options.type }}</div>
          <FormItem
            id="asset-group"
            :value="options.selectedAssetGroupId"
            class="input-fx-pop"
            type="select2"
            :options="sortedAssetGroups.map(ag => ({ label: ag.name, value: ag._id }))"
            @input="onSelectAssetGroup"
          />
        </div> -->
      </div>
      <router-view v-if="selectedAssetGroup._id || selectedAsset._id" />
      <div v-else>
        <div class="mt-5 text-center alert alert-warning">Select a site above to view analytics.</div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import FormItem from '@/components/FormItem';
import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import { capitalize } from '@/lib/helpers';

export default {
  name: 'AnalyticsBase',
  components: {
    FormItem,
    PageHeaderDisplay
  },
  computed: {
    ...mapGetters({
      analytics: 'analytics/analytics',
      options: 'analytics/options',
      selectedAsset: 'analytics/selectedAsset',
      selectedAssetGroup: 'analytics/selectedAssetGroup',
      loadingAction: 'analytics/loadingAction',
      errorAction: 'analytics/errorAction',
      allAssets: 'analytics/allAssets',
      allAssetGroups: 'analytics/allAssetGroups',
      comparePeriods: 'analytics/comparePeriods'
    }),
    sortedAssets() {
      const sortedAssets = [...this.allAssets];

      sortedAssets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      return sortedAssets;
    },
    sortedAssetGroups() {
      const sortedAssetGroups = [...this.allAssetGroups];

      sortedAssetGroups.sort((a, b) => a.name.localeCompare(b.name));

      return sortedAssetGroups;
    },
    pageTitle() {
      if (this.options.selectedAssetId && this.options.type === 'asset') {
        return `${this.selectedAsset.siteName} - ${capitalize(this.$route.path.split('/').pop())} Dashboard`;
      } else if (this.options.selectedAssetGroupId && this.options.type === 'portfolio') {
        return `${this.selectedAssetGroup.name} - ${capitalize(this.$route.path.split('/').pop())} Dashboard`;
      }

      return '';
    }
  },
  async mounted() {
    this.loadAnalytics();
  },
  beforeDestroy() {
    this.clearAnalyticsStore();
  },
  methods: {
    ...mapActions({
      getAssetGroups: 'analytics/getAssetGroups',
      getAssets: 'analytics/getAssets',
      refreshGraphs: 'analytics/refreshGraphs',
      getAccounts: 'asset/accounts'
    }),
    ...mapMutations({
      setOption: 'analytics/SET_OPTION',
      selectAsset: 'analytics/SELECT_ASSET',
      selectAssetGroup: 'analytics/SELECT_ASSET_GROUP',
      updateComparisonPeriods: 'analytics/UPDATE_COMPARISON_PERIODS',
      clearAnalyticsStore: 'analytics/CLEAR_STORE'
    }),
    async loadAnalytics() {
      this.setOption({ key: 'page', option: this.$route.path.split('/').pop() });

      await Promise.all([this.getAssets({ data: { params: { $limit: 99999, $select: '_id,floorArea,siteName' } } }), this.getAssetGroups()]);

      const { type, id, dateRange, comparePeriod, source } = this.$route.query;

      if (type && id) {
        if (type === 'asset') {
          this.selectAsset(id);
          this.getAccounts({ id });
        } else {
          this.selectAssetGroup(id);
        }

        if (source) {
          this.setOption({ key: 'source', option: source });
        }

        if (dateRange) {
          const [startDate, endDate] = dateRange.split('|');
          this.options.dateRange = [moment(startDate).toDate(), moment(endDate).toDate()];
          if (comparePeriod) this.options.comparePeriod = comparePeriod;
          this.updateComparisonPeriods();
        }

        this.refreshGraphs();
      }
    },
    onComparePeriodUpdate(period) {
      this.setOption({ key: 'comparePeriod', option: period });
      this.refreshGraphs();
      this.updateRoute();
    },
    onDateRangeUpdate() {
      this.updateComparisonPeriods();
      this.refreshGraphs();
      this.updateRoute();
    },
    onSelectAsset(id) {
      this.selectAsset(id);
      this.getAccounts({ id });
      this.refreshGraphs();
      this.updateRoute();
    },
    onSelectAssetGroup(id) {
      this.selectAssetGroup(id);
      this.refreshGraphs();
      this.updateRoute();
    },
    onSourceUpdate(source) {
      this.setOption({ key: 'source', option: source });
      this.refreshGraphs();
      this.updateRoute();
    },
    updateRoute() {
      const { type, selectedAssetId, selectedAssetGroupId, dateRange, comparePeriod, source } = this.options;

      const updatedQuery =
        selectedAssetId || selectedAssetGroupId
          ? {
              type,
              id: type === 'asset' ? selectedAssetId : selectedAssetGroupId,
              dateRange: moment(dateRange[0]).format('YYYY-MM-DD') + '|' + moment(dateRange[1]).format('YYYY-MM-DD'),
              comparePeriod,
              source
            }
          : {};

      if (Object.keys(updatedQuery).every(key => this.$route.query[key] === updatedQuery[key])) return false;

      this.$router.push({
        name: this.$route.name,
        query: updatedQuery
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log(from, to);
    if (to.path !== from.path) {
      this.setOption({ key: 'page', option: to.path.split('/').pop() });
      this.refreshGraphs();
    }
    next();
  }
};
</script>
